body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #ffff;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
	font-size: x-large;
	color: #004964;
	border-width: 5px;
	border-color: #c4d8e1;
	margin-left: 5px;
	background: #000;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.main-content {
   padding-top: 60px;
	transition: margin-left 0.5s !important;
   height: calc(100vh - 60px) !important;
}

.menu-row-item {
   transition: margin-left 1.5s !important;
}

.menu-row-title {
   transition: margin-left 0.8s !important;
}

.main-content-segment {
   height: calc(100vh - 60px) !important;
}

.Circle_Icon {
   cursor: pointer !important;
   background-color: whitesmoke !important;
   color: #1F4670 !important;
}

.InputField {
	width: 100%;
	border: none;
	padding: 0 1rem;
}

.InputField:focus {
	outline: none;
}

div[tabindex='-1']:focus {
	outline: 0;
}

/* Header */

.Sidebar {
	display: flex;
	justify-content: space-between;
	/* border-bottom: 1px solid red; */
	box-shadow: 1px 7px 11px -4px rgba(221, 221, 221, 0.76);
}

.Sidebar h1 {
	margin: 1rem;
}

.SidebarNavBar {
	margin: 1rem;
	display: flex;
	list-style-type: none;
}

.SidebarNavBar li {
	padding: 0.5rem 1rem;
}

.breadcrumTitle {
	margin: 1rem !important;
	font-size: 1.3rem !important;
}

.PageContainer::-webkit-scrollbar {
	width: 0px;
	height: 10px;
}

/* Track */

.PageContainer::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */

.PageContainer::-webkit-scrollbar-thumb {
	background: #c1c1c1;
	border-radius: 10px;
}

/* Handle on hover */

.PageContainer::-webkit-scrollbar-thumb:hover {
	background: #9e9e9e;
}

.PageContainer {
	margin: 0.5rem 1.5rem;
	margin-top: 1vh;
}

.login-background {
	background: linear-gradient(-45deg, #f78c0f, #004964, #c4d8e1, #539abd);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

.main-icon-background {
	animation: animation-button 15s ease infinite;
}

.main-text-background {
	animation: animation-button 15s ease infinite reverse;
}

@keyframes gradient {
	0% {
		back: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-webkit-keyframes animation-button {
	0% {
		color: #f78c0f;
	}
	25% {
		color: #004964;
	}
	50% {
		color: #c4d8e1;
	}
	75% {
		color: #539abd;
	}
	100% {
		color: #f78c0f;
	}
}

@keyframes animation-button {
	0% {
		color: #f78c0f;
	}
	25% {
		color: #004964;
	}
	50% {
		color: #c4d8e1;
	}
	75% {
		color: #539abd;
	}
	100% {
		color: #f78c0f;
	}
}
