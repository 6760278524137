.menu-sidebar {
  border-top: 0;
  height: 100vh;
  background: #114485;
  border-radius: 0;
  overflow-x: hidden;
}

.menu-sidebar-header-header {
  width: 3rem;
  display: inline-block;
  vertical-align: middle;
}

.menu-sidebar-header-title {
  width: '11rem';
  display: inline-block;
  vertical-align: middle;
}

.menu-sidebar-header-question {
  width: 2rem;
  display: inline-block;
  vertical-align: middle;
}

.menu-sidebar-header-log-out {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-left: 0.3rem;
}

.menu-row-title {
  display: inline-block;
  margin-top: 0 !important;
  margin-left: 10px !important;
  color: #d5d5d5 !important;
  -webkit-transition: all 0.5s ease-in-out !important;
	-moz-transition: all 0.5s ease-in-out !important;
	-o-transition: all 0.5s ease-in-out !important;
	transition: all 0.5s ease-in-out !important;
}

.menu_row :hover {
  background: #d5d5d5 !important;
  transition: all 0.3s ease-in-out !important;
}

.sub_menu_row :hover {
  background: #d5d5d5 !important;
  text-decoration: underline !important;
  transition: all 0.3s ease-in-out !important;
}
